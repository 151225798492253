import React from 'react';

import { WmsIcon, AdmIcon, InvIcon, ImpIcon } from '../assets/ModulesIcons';

export function handleIcons(iconName: string): JSX.Element {
  switch (iconName) {
    case 'wms':
      return <WmsIcon />;
    case 'inv':
      return <InvIcon />;
    case 'pre':
      return <WmsIcon />;
    case 'imp':
      return <ImpIcon />;
    default:
      return <AdmIcon />;
  }
}
